import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import JumboPhoto from "../../components/jumbophoto"
import { Container, Row, Col, ResponsiveEmbed } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"

export default () => <Layout>
	<Helmet title="Locations | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1>Locations</h1>
				<p>Serving individuals and families throughout the communities of Bessemer, Birmingham, Clanton, Columbiana, Calera, Helena, Homewood, Leeds, Moody, Montevallo, Pelham, Alabaster, Oneonta, Pell City, Vestavia Hills, Hoover, Mountain Brook, as well as Jefferson County, Shelby County, Blount County, St. Clair County, Chilton County, Marion, Winston, Lamar, Fayette, Walker, Pickens, Tuscaloosa, Bibb, Greene, Sumter, Lauderdale, Colbert, Franklin, Lawreence, Morgan, Madison, Jackson, Winston, Cullman, and Jasper.</p>

				<p>Contact us if you don't see your county listed!</p>
			</Col>
		</Row>
		<Row>
			<Col>
				<ResponsiveEmbed aspectRatio="16by9">
					<iframe title="Map of Valleydale Office" src="https://www.google.com/maps/d/u/0/embed?mid=1ydclgjxw1YqGJd41e-WCc5QE8Alf32WI"></iframe>
				</ResponsiveEmbed>
			</Col>
		</Row>


		<Row className="my-4">
			<Col>
				<div className="d-flex align-items-center bg-light p-3">
					<FontAwesomeIcon className="mx-3" icon={faMapMarkerAlt} style={{ fontSize: '4rem' }} />
					<address>
						<strong>Birmingham Office</strong><br />
						2057 Valleydale Road, Suite 202<br />
						Birmingham, AL 35244<br />
						Phone: 205-982-3325<br />
						Fax: 205-982-7070
					</address>
				</div>
			</Col>
			<Col>
				<div className="d-flex align-items-center bg-light p-3">
					<FontAwesomeIcon className="mx-3" icon={faMapMarkerAlt} style={{ fontSize: '4rem' }} />

					<address>
						<strong>Oneonta Office</strong><br />
					305 1st Avenue East<br />
					Oneonta AL 35121<br />
					Phone: 205-625-3325<br /><br />
					</address>
				</div>
			</Col>
			<Col>
				<div className="d-flex align-items-center bg-light p-3">
					<FontAwesomeIcon className="mx-3" icon={faMapMarkerAlt} style={{ fontSize: '4rem' }} />

					<address>
						<strong>Jasper Office</strong><br />
					1810 3rd Ave #102<br />
					Jasper AL 35501<br />
					Phone: 205-522-7727<br /><br />
					</address>
				</div>
			</Col>
		</Row>
	</Container>
</Layout>